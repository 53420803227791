import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  from,
  merge,
  of,
} from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { APP_DATE_FORMATS, AppDateAdapter } from '@app/DateAdapter';
import { noWhitespaceValidator, validateDates } from '@app/Validators';
import { selectProjectByIdFactory$ } from '@app/collections/projects/store/projects.selectors';
import { ProjectWithDetailsDto } from '@app/models/backendModel';
import { SdkCallService } from '@app/sdk-call.service';
import { selectAllActiveClients } from '@collections/clients/store/clients.selectors';
import { CurrenciesApiService } from '@collections/currencies/currencies-api.service';
import { selectAllCurrencies } from '@collections/currencies/store/currencies.selectors';
import { selectAllEntities } from '@collections/entities/store/entities.selectors';
import { selectAllActiveProjectStages } from '@collections/project-stages/store/project-stages.selectors';
import { selectAllActiveProjectTypes } from '@collections/project-types/store/project-types.selectors';
import { ProjectsApiService } from '@collections/projects/projects-api.service';
import {
  selectCurrentBusinessSegmentId,
  selectCurrentUser,
  selectCurrentUserEntityId,
  selectCurrentUserIsRequestor,
} from '@collections/users/store/users.selectors';
import { ProjectStatusEnum } from '@common/enums';
import {
  deepClone,
  formToServerDateConversion,
  monthDiff,
} from '@common/utils';
import {
  selectCurrentRouteParamFactory,
  selectCurrentRouteUserRole,
} from '@core/store/core.selectors';
import { IGetProjectsCurrenciesResponseItem } from '@models/currencies';
import { Entity } from '@models/entity';
import { ProjectFormData, emptyProject } from '@models/project-form-data';

export interface ProjectCurrencyFormState {
  currencyCode: string;
  isDefaultCurrency: boolean;
  /** currency to project default currency rate that may not be USD */
  fxRate: number | string;
}

export interface CurrencyRawData {
  currencyCode: string;
  fxRate: string | number;
}
export interface ProjectCurrencyFormGroup {
  currencyCode: FormControl<string>;
  isDefaultCurrency: FormControl<boolean>;
  fxRate: FormControl<number | string>;
}
@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class CreateProjectComponent implements OnInit, OnDestroy {
  public projectFormGroup: UntypedFormGroup;
  public currencyFormGroup: FormArray<FormGroup<ProjectCurrencyFormGroup>> =
    null;
  public submitted = false;
  private projectId: number;
  public activeClients$ = this.store.select(selectAllActiveClients);
  public viewPage = false;
  public notOwnEntity = false;
  private saveNext = false;
  private savedProject = {};
  public projectDataChanged = false;
  public currenciesDataChanged = false;
  private componentDestroyed$ = new ReplaySubject<boolean>(1);
  private originalFormValue: { [key: string]: any };

  /**
   * treat it as ngrx store containing relations between currencies for specific project
   * when recalculating rates in form use it single source of truth
   * if user updates rate update it in this variable
   */
  private projectCurrenciesFxRatesStore: { [currencyCode: string]: number };
  private errors: any[] = [];
  public uniqueErrors: any[] = [];
  public cannotMakeDefaultError = false;

  public userBusinessSegmentId$ = this.store
    .select(selectCurrentBusinessSegmentId)
    .pipe(filter((v) => !!v));

  private userEntityId$ = this.store
    .select(selectCurrentUserEntityId)
    .pipe(filter((v) => !!v));

  private currentUser$ = this.store
    .select(selectCurrentUser)
    .pipe(filter((v) => !!v));

  private allEntities$ = this.store
    .select(selectAllEntities)
    .pipe(filter((v) => v?.length > 0));

  public currencies$ = this.store.select(selectAllCurrencies);

  private userEntity$ = combineLatest([
    this.userEntityId$,
    this.allEntities$,
  ]).pipe(
    map(
      ([userEntityId, entities]) =>
        entities.find(({ entityId }) => entityId === userEntityId) || null
    )
  );

  private userIsRequestor$ = this.store.select(selectCurrentUserIsRequestor);

  public activeProjectTypes$ = this.store.select(selectAllActiveProjectTypes);

  public activeProjectStages$ = this.store.select(selectAllActiveProjectStages);

  public defaultCurrencyCode: string;
  private projectCurrenciesInitialState: ProjectCurrencyFormState[];
  private projectExecutingEntityId: number;
  public selectedTabIndex: number;

  public routeProjectId$ = this.store.select(
    selectCurrentRouteParamFactory('projectId')
  );

  private projectCurrenciesData$ = this.routeProjectId$.pipe(
    switchMap((projectId) =>
      projectId
        ? this.currenciesApiService.getProjectCurrencies(projectId)
        : this.currenciesApiService.getDefaultProjectCurrencies()
    ),
    map((currenciesData) => this.mapFetchedCurrencies(currenciesData))
  );

  constructor(
    private formBuilder: FormBuilder,
    private sdkService: SdkCallService,
    private router: Router,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private store: Store,
    private projectsApiService: ProjectsApiService,
    private currenciesApiService: CurrenciesApiService
  ) {}

  public getErrors(formControlName: string) {
    return this.projectFormGroup.controls[formControlName].errors;
  }

  private fetchGlobalCurrencies() {
    return this.currencies$.pipe(
      switchMap((data) => {
        this.projectCurrenciesFxRatesStore = data.reduce(
          (r, { currencyCode, usdfxRate }) => ({
            ...r,
            [currencyCode]: usdfxRate,
          }),
          {}
        );

        return this.fetchProjectCurrencies$();
      })
    );
  }

  private fetchProjectCurrencies$() {
    return this.projectCurrenciesData$.pipe(
      take(1),
      tap((projectCurrencies) => {
        const defaultCurrencyRateToUSD =
          this.getDefaultCurrencyRate(projectCurrencies);

        this.projectCurrenciesFxRatesStore = projectCurrencies.reduce(
          (r, { currencyCode, fxRate: rateToDefaultCurrency }) => ({
            ...r,
            [currencyCode]: parseFloat(
              (
                parseFloat(rateToDefaultCurrency.toString()) *
                defaultCurrencyRateToUSD
              ).toFixed(4)
            ),
          }),
          this.projectCurrenciesFxRatesStore
        );

        this.buildCurrencyFormGroup(projectCurrencies);
        this.listenForCurrencyFormChanges();
      })
    );
  }

  private buildCurrencyFormGroup(
    projectCurrencies: ProjectCurrencyFormState[]
  ): void {
    this.currencyFormGroup = this.createFormArrayFromList(projectCurrencies);

    this.handleCurrencyFormEnablement();
  }

  private handleCurrencyFormEnablement(): void {
    // disable selecting different default currency when project has already completed CTRs
    const { hasCompletedCTR } = this.projectFormGroup.getRawValue();
    if (this.notOwnEntity) {
      this.currencyFormGroup.disable();
    }
    if (hasCompletedCTR) {
      this.currencyFormGroup.controls.forEach((control) => {
        control.controls.isDefaultCurrency.disable();
      });
    }

    // disable editing fxRate for current default currency
    const defaultCurrencyControl = this.currencyFormGroup.controls.find(
      (control) => control.getRawValue().isDefaultCurrency
    );

    defaultCurrencyControl?.controls.fxRate.disable();
  }

  private listenForCurrencyFormChanges(): void {
    this.currencyFormGroup.valueChanges.subscribe((newCurrencies) => {
      this.currenciesDataChanged =
        this.checkIfCurrenciesDataChanged(newCurrencies);
    });
  }

  private mapFetchedCurrencies(
    projectCurrencyConfig: IGetProjectsCurrenciesResponseItem[]
  ) {
    const formatedCurrencies: ProjectCurrencyFormState[] =
      projectCurrencyConfig.map((projectCurrency) => {
        return {
          currencyCode: projectCurrency.currencyCode,
          isDefaultCurrency: projectCurrency.isDefaultCurrency,
          fxRate: parseFloat(projectCurrency.fxRate.toString()).toFixed(4),
        };
      });

    const defaultCurrency = formatedCurrencies.find(
      ({ isDefaultCurrency }) => isDefaultCurrency
    );
    this.defaultCurrencyCode = defaultCurrency?.currencyCode;

    formatedCurrencies.sort((a, b) =>
      a.currencyCode > b.currencyCode ? 1 : -1
    );

    this.projectCurrenciesInitialState = deepClone(formatedCurrencies);
    this.currenciesDataChanged = false;

    return formatedCurrencies;
  }

  public ngOnInit(): void {
    this.sdkService.promptEnable = true;

    this.routeProjectId$.subscribe((projectId) => {
      this.projectId = projectId;
    });

    combineLatest([this.routeProjectId$, this.userBusinessSegmentId$])
      .pipe(take(1))
      .subscribe(([projectId, userBusinessSegmentId]) => {
        this.createProjectDataFormGroup(
          projectId !== null,
          userBusinessSegmentId
        );
      });

    combineLatest([
      this.routeProjectId$,
      this.userIsRequestor$,
      this.userEntityId$,
      this.store.select(selectCurrentRouteUserRole),
    ])
      .pipe(take(1))
      .subscribe(([projectId, userIsRequestor, userEntityId, userRole]) => {
        if (projectId) {
          this.viewPage = true;

          selectProjectByIdFactory$(this.store, projectId, userRole).subscribe(
            (projectData) => {
              this.projectFormGroup.patchValue(projectData);
              this.projectExecutingEntityId = projectData.executingEntityId;

              this.populateMasterApis();

              if (
                userIsRequestor &&
                projectData.executingEntityId !== userEntityId
              ) {
                this.notOwnEntity = true;
                this.projectFormGroup.disable();
              } else {
                this.notOwnEntity = false;
              }

              this.initializeProjectFormState();
            }
          );
        } else {
          this.populateMasterApis();
        }
      });
  }

  private initializeProjectFormState() {
    this.originalFormValue = {
      ...this.projectFormGroup.getRawValue(),
    };
    this.savedProject = this.filterProjectsDataToStore({
      ...this.projectFormGroup.value,
    });
    this.projectFormGroup.updateValueAndValidity();
    this.projectDataChanged = this.checkIfProjectDataChanged();
  }

  public ngOnDestroy(): void {
    this.saveNext = false;
    this.sdkService.promptEnable = false;
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private createProjectDataFormGroup(
    edit: boolean,
    userBusinessSegmentId: number
  ): void {
    const projectStartDate = new Date();
    projectStartDate.setDate(projectStartDate.getDate() + 164);

    const projectEndDate = new Date();
    projectEndDate.setDate(projectStartDate.getDate() + 718);

    this.projectFormGroup = this.formBuilder.group(
      {
        projectName: [
          { value: '', disabled: this.notOwnEntity },
          [
            Validators.required,
            Validators.maxLength(60),
            noWhitespaceValidator,
          ],
        ],
        projectMDMNumber: [
          { value: '', disabled: this.notOwnEntity },
          [
            Validators.required,
            Validators.maxLength(20),
            noWhitespaceValidator,
          ],
        ],
        workPackageNumber: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(18)],
        ],
        projectStatus: [
          { value: ProjectStatusEnum.NOT_STARTED, disabled: true },
        ],
        requestorName: [{ value: '', disabled: true }],
        clientId: [{ value: '', disabled: edit }, [Validators.required]],
        projectTypeId: [{ value: '', disabled: false }, Validators.required],
        executingEntityId: ['1'],
        executingEntity: [{ value: '', disabled: true }, [Validators.required]],
        totalDuration: [
          {
            value: monthDiff(projectStartDate, projectEndDate) + ' months',
            disabled: true,
          },
          [Validators.required],
        ],
        projectStageId: ['', Validators.required],
        projectAwardDate: [''],
        projectStartDate: [projectStartDate, [Validators.required]],
        projectBidDate: [''],
        projectEndDate: [projectEndDate, Validators.required],
        pressureElevation: ['', Validators.maxLength(50)],
        fieldLocation: [
          { value: '', disabled: this.notOwnEntity },
          [
            Validators.required,
            Validators.maxLength(50),
            noWhitespaceValidator,
          ],
        ],
        fieldLocationNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        waterDepth: [
          { value: null, disabled: this.notOwnEntity },
          [Validators.required, Validators.pattern('^\\d*$')],
        ],
        waterDepthNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        maxDesignPressure: [
          { value: null, disabled: this.notOwnEntity },
          [Validators.pattern('^\\d*$')],
        ],
        maxDesignTemp: [
          { value: null, disabled: this.notOwnEntity },
          [Validators.pattern('^\\d*$')],
        ],
        designTempNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        designPressureNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        enggPhaseDurationNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        opPhaseDurationNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        projectStartDateNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        projectEndDateNotes: [
          { value: '', disabled: this.notOwnEntity },
          [Validators.maxLength(800)],
        ],
        enggPhaseDuration: [
          { value: null, disabled: this.notOwnEntity },
          [Validators.pattern('^\\d*$')],
        ],
        opPhaseDuration: [
          { value: null, disabled: this.notOwnEntity },
          [Validators.pattern('^\\d*$')],
        ],
        businessSegmentId: [userBusinessSegmentId],
        hasCompletedCTR: [{ value: false, disabled: true }],
      },
      {
        validators: [
          validateDates(
            'projectStartDate',
            'projectEndDate',
            'projectStartDate'
          ),
          validateDates(
            'projectAwardDate',
            'projectEndDate',
            'projectAwardDate'
          ),
          validateDates('projectBidDate', 'projectEndDate', 'projectBidDate'),
        ],
      }
    );

    this.handleProjectFormChanges();
  }

  private handleProjectFormChanges() {
    merge(
      this.projectFormGroup.get('projectStartDate').valueChanges,
      this.projectFormGroup.get('projectEndDate').valueChanges
    )
      .pipe(startWith(null))
      .subscribe(() => {
        this.calcTotalDuration();
      });

    this.projectFormGroup.valueChanges.subscribe(() => {
      this.projectDataChanged = this.checkIfProjectDataChanged();
    });
  }

  /**
   * TODO: candidate for extracting to form generation service
   */
  private createFormArrayFromList(
    list: ProjectCurrencyFormState[]
  ): FormArray<FormGroup<ProjectCurrencyFormGroup>> {
    return this.formBuilder.array(
      list.map((data) => this.createFormGroupFromData(data))
    );
  }

  /**
   * TODO: candidate for extracting to form generation service
   */
  private createFormGroupFromData(
    data: ProjectCurrencyFormState
  ): FormGroup<ProjectCurrencyFormGroup> {
    const formGroup = this.formBuilder.group<ProjectCurrencyFormGroup>({
      currencyCode: this.formBuilder.control(
        data.currencyCode,
        Validators.required
      ),
      fxRate: this.formBuilder.control(data.fxRate, [
        Validators.required,
        Validators.pattern(/^(\d{0,4}(\.\d{0,4})?)$/),
      ]),
      isDefaultCurrency: this.formBuilder.control(data.isDefaultCurrency),
    });
    formGroup.controls.currencyCode.disable();

    return formGroup;
  }

  private getDefaultCurrencyRate(
    currencies = this.currencyFormGroup.getRawValue()
  ) {
    const defaultCurrency = currencies.find(
      ({ isDefaultCurrency }) => isDefaultCurrency
    );
    const defaultCurrencyCode = defaultCurrency?.currencyCode;

    return this.projectCurrenciesFxRatesStore[defaultCurrencyCode] || 1;
  }

  private checkIfCurrenciesDataChanged(
    newCurrencies: Partial<{
      currencyCode: string;
      isDefaultCurrency: boolean;
      fxRate: string | number;
    }>[]
  ): boolean {
    if (this.currencyFormGroup.disabled || !this.currencyFormGroup.valid) {
      return false;
    }
    const areAllSame =
      this.projectCurrenciesInitialState
        .reduce<boolean[]>((r, initialCurrency) => {
          const currency = newCurrencies.find(
            ({ currencyCode: newCurrencyCode }) =>
              initialCurrency.currencyCode === newCurrencyCode
          );
          if (!currency) {
            r.push(false);
            return r;
          }
          if (
            (initialCurrency.isDefaultCurrency ||
              initialCurrency.fxRate == currency.fxRate) &&
            initialCurrency.isDefaultCurrency == currency.isDefaultCurrency
          ) {
            r.push(true);
            return r;
          }
          r.push(false);
          return r;
        }, [])
        .filter((x) => !x).length == 0;

    return !areAllSame;
  }

  private checkIfProjectDataChanged(): boolean {
    return (
      !this.projectFormGroup.disabled &&
      this.projectFormGroup.valid &&
      !this.compareForms(
        this.savedProject,
        this.filterProjectsDataToStore(this.projectFormGroup?.value)
      )
    );
  }

  public setAsDefaultCurrency(
    currencyFormControl: FormGroup<ProjectCurrencyFormGroup>,
    event?
  ): void {
    if (event) {
      event.preventDefault();
    }

    if (currencyFormControl) {
      if (currencyFormControl.controls.isDefaultCurrency.disabled) {
        return;
      }

      const currencyData = currencyFormControl.getRawValue();

      const defaultCurrencyFxRate =
        this.projectCurrenciesFxRatesStore[currencyData.currencyCode] || 1;

      this.defaultCurrencyCode = currencyData.currencyCode;

      this.currencyFormGroup.controls.forEach((currencyForm) => {
        const controlCurrencyCode = currencyForm.controls.currencyCode.value;
        const rate = this.projectCurrenciesFxRatesStore[controlCurrencyCode];

        const fxRateControl = currencyForm.controls.fxRate;

        if (
          currencyForm.controls.currencyCode.value !== currencyData.currencyCode
        ) {
          currencyForm.controls.isDefaultCurrency.setValue(false);
          fxRateControl.setValue((rate / defaultCurrencyFxRate).toFixed(4), {
            onlySelf: true,
            emitEvent: false,
          });
          fxRateControl.enable();
          fxRateControl.updateValueAndValidity();
        } else {
          currencyForm.controls.isDefaultCurrency.setValue(true);
          fxRateControl.setValue('1.0000', {
            onlySelf: true,
            emitEvent: false,
          });
          fxRateControl.disable();
          fxRateControl.updateValueAndValidity();
        }

        currencyForm.updateValueAndValidity();
      });
    }

    this.currencyFormGroup.updateValueAndValidity();
  }

  public changeCurrencyConfiguration(
    currencyControl: FormGroup<ProjectCurrencyFormGroup>
  ): void {
    const currencyData = currencyControl.getRawValue();
    const currencyCode = currencyData.currencyCode;
    const fxRateControl = currencyControl.controls.fxRate;

    const defaultCurrencyRate = this.getDefaultCurrencyRate();

    const fxRateToDefault = parseFloat(currencyData.fxRate.toString());

    if (Number.isNaN(fxRateToDefault) || !Number.isFinite(fxRateToDefault)) {
      fxRateControl.setValue(fxRateToDefault, {
        onlySelf: true,
        emitEvent: false,
      });
      this.errors.push({ id: currencyCode, error: 'pattern' });
      this.updateCurrencyFormErrors();
      this.currencyFormGroup.updateValueAndValidity();
      return;
    }

    this.projectCurrenciesFxRatesStore[currencyCode] =
      fxRateToDefault * defaultCurrencyRate;

    fxRateControl.setValue(parseFloat(fxRateToDefault.toString()).toFixed(4), {
      onlySelf: true,
      emitEvent: false,
    });

    if (currencyControl.controls.fxRate.errors?.pattern) {
      this.errors.push({ id: currencyCode, error: 'pattern' });
    } else {
      this.errors = this.errors.filter(
        (x) =>
          (x.id === currencyCode && x.error !== 'pattern') ||
          x.id !== currencyCode
      );
    }

    this.updateCurrencyFormErrors();
    this.currencyFormGroup.updateValueAndValidity();
  }

  private updateCurrencyFormErrors() {
    this.uniqueErrors = [];
    this.errors?.forEach((err) => {
      const index = this.uniqueErrors.findIndex((x) => x === err.error);
      if (index === -1) {
        this.uniqueErrors.push(err.error);
      }
    });
  }

  private saveCurrencyData$(
    projectId: number
  ): Observable<ProjectCurrencyFormState[]> {
    const currenciesData = this.currencyFormGroup
      .getRawValue()
      .filter(({ currencyCode }) => !!currencyCode)
      .map((currencyConfig) => deepClone({ ...currencyConfig, projectId }));

    return this.currenciesApiService
      .updateProjectCurrencies(
        currenciesData.map((x) => ({
          ...x,
          fxRate: parseFloat(x.fxRate.toString()),
        })),
        projectId
      )
      .pipe(
        take(1),
        switchMap(() => this.fetchProjectCurrencies$())
      );
  }

  private populateMasterApis(): void {
    combineLatest([this.userEntity$, this.allEntities$, this.currentUser$])
      .pipe(
        take(1),
        switchMap(([userEntityData, entitiesData, currentUserData]) => {
          const { executingEntity, executingEntityId, requestorName } =
            this.projectFormGroup.controls;

          if (!!userEntityData && !this.viewPage) {
            requestorName.setValue(currentUserData.fullName);
            executingEntityId.patchValue(userEntityData.entityId);
            executingEntity.setValue(userEntityData.enggCenter);
          } else {
            executingEntity.setValue(
              entitiesData.find(
                (entity: Entity) =>
                  entity.entityId === this.projectExecutingEntityId
              )?.enggCenter
            );
          }

          executingEntity.disable();

          return this.fetchGlobalCurrencies();
        })
      )
      .subscribe();
  }

  private calcTotalDuration() {
    const { projectStartDate, projectEndDate, totalDuration } =
      this.projectFormGroup.controls;

    if (
      projectStartDate.value !== '' &&
      projectEndDate.value !== '' &&
      projectStartDate.valid &&
      projectEndDate.valid
    ) {
      const startDate = new Date(projectStartDate.value);
      const endDate = new Date(projectEndDate.value);
      totalDuration.setValue(monthDiff(startDate, endDate) + ' months', {
        emitEvent: false,
      });
    } else {
      totalDuration.setValue('0 months', {
        emitEvent: false,
      });
    }
  }

  private compareForms(savedForm, currentForm) {
    const formKeys = Object.keys(savedForm);
    const dateFieldsKeys = [
      'projectEndDate',
      'projectStartDate',
      'projectBidDate',
      'projectAwardDate',
    ];

    if (formKeys.length !== Object.keys(currentForm).length) {
      return false;
    }

    for (let i = 0; i <= formKeys.length; i++) {
      const savedFormValue = savedForm[formKeys[i]];
      const currentFormValue = currentForm[formKeys[i]];
      const fieldIsDate = dateFieldsKeys.includes(formKeys[i]);

      // TODO: some fields are stored as null and when editing to empty string they report as changed form
      // @see for example enggPhaseDurationNotes
      if (
        savedFormValue != currentFormValue &&
        ((fieldIsDate &&
          !this.compareDates(savedFormValue, currentFormValue)) ||
          (!fieldIsDate &&
            (savedFormValue !== null || currentFormValue !== '')))
      ) {
        return false;
      }
    }

    return true;
  }

  private compareDates(savedDate: string, currentDate: string) {
    const saved = new Date(savedDate);
    const current = new Date(currentDate);
    saved.setHours(0, 0, 0, 0);
    current.setHours(0, 0, 0, 0);
    if (saved !== null && current !== null) {
      return saved.toDateString() === current.toDateString();
    } else {
      return false;
    }
  }

  private dateConversion() {
    [
      'projectEndDate',
      'projectStartDate',
      'projectBidDate',
      'projectAwardDate',
    ].forEach((dateFormFieldName) => {
      const convertedDate = formToServerDateConversion(
        this.projectFormGroup.value[dateFormFieldName]
      );

      if (this.projectFormGroup.value[dateFormFieldName] !== convertedDate) {
        this.projectFormGroup
          .get(dateFormFieldName)
          .setValue(convertedDate, { emitEvent: false });
      }
    });
  }

  public onSave(): void {
    if (!this.projectFormGroup.valid || !this.currencyFormGroup.valid) {
      this.projectFormGroup.markAllAsTouched();
      this.currencyFormGroup.markAllAsTouched();
      const firstInvalidControl =
        document.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
      this.saveNext = false;
      return;
    }
    if (this.submitted || this.uniqueErrors.length != 0) {
      return;
    }
    this.submitted = true;
    this.dateConversion();

    this.routeProjectId$
      .pipe(
        takeUntil(this.componentDestroyed$),
        take(1),
        map((projectId) => parseFloat(projectId?.toString())),
        switchMap((projectId) => {
          let requests: Observable<boolean> = of(true);
          if (projectId && this.currenciesDataChanged) {
            requests = requests.pipe(
              switchMap(() =>
                this.saveCurrencyData$(projectId).pipe(map(() => true))
              )
            );
          }

          if (this.projectDataChanged) {
            requests = requests.pipe(switchMap(() => this.sendData$()));
          }
          return requests;
        })
      )
      .subscribe(() => {
        this.submitted = false;
      });
  }

  public onSaveAndNext(): void {
    // saving data
    this.saveNext = true;
    this.onSave();
  }

  private navigateToScenario(projectId: number): Observable<boolean> {
    return from(this.router.navigate(['/scenario', projectId]));
  }

  private navigateToProject(projectId: number): Observable<boolean> {
    return from(this.router.navigate(['/project', projectId, 'edit']));
  }

  public onBack() {
    this.sdkService.openUnsavedChangesModal().subscribe((isActionConfirmed) => {
      if (isActionConfirmed) {
        this.router.navigate(['../']);
      }
    });
  }

  public canDeactivate() {
    if (Object.keys(this.savedProject).length > 0) {
      return this.checkIfProjectDataChanged();
    } else {
      return false;
    }
  }

  private sendData$(): Observable<boolean> {
    this.projectFormGroup.markAllAsTouched();

    if (this.projectFormGroup.invalid) {
      return of();
    }
    return this.getProjectDataToPersist$().pipe(
      take(1),
      switchMap((projectData) => {
        if (!projectData.projectId) {
          projectData.currencies = this.currencyFormGroup
            .getRawValue()
            .map((currency) => ({
              ...currency,
              fxRate: parseFloat(currency.fxRate.toString()),
            }));

          return this.postNewProjectData$(projectData);
        } else {
          return this.putProjectEditChanges$(projectData).pipe(map(() => true));
        }
      })
    );
  }

  private postNewProjectData$(project: ProjectFormData): Observable<boolean> {
    return this.projectsApiService.addProject(project).pipe(
      switchMap((projectId) => this.projectsApiService.getProject(projectId)),
      switchMap((data: ProjectWithDetailsDto) => {
        this.snackBar.open(
          `Project ${data.projectName} added successfully`,
          null,
          {
            duration: 5000,
          }
        );

        if (this.saveNext) {
          return this.navigateToScenario(data.id);
        } else {
          return this.navigateToProject(data.id);
        }
      })
    );
  }

  private putProjectEditChanges$(
    project: ProjectFormData
  ): Observable<ProjectWithDetailsDto> {
    return this.projectsApiService.putProject(project).pipe(
      // TODO: put response is useless in current format
      // redirecting to data loaded from getProject
      switchMap(() => this.projectsApiService.getProject(project.projectId)),

      tap((data) => {
        data.id = parseFloat(data.id.toString());
        if (this.currenciesDataChanged) {
          this.saveCurrencyData$(data.id).subscribe();
        }

        this.handleProjectSaveResponse(data);

        this.snackBar.open(
          `Project ${data.projectName} edited successfully`,
          null,
          {
            duration: 5000,
          }
        );
      })
    );
  }

  private handleProjectSaveResponse(projectsData: ProjectWithDetailsDto): void {
    this.projectFormGroup.patchValue(projectsData);

    this.initializeProjectFormState();

    this.projectId = projectsData.id;
  }

  private getProjectDataToPersist$() {
    return combineLatest([
      this.userBusinessSegmentId$,
      this.userEntityId$,
      this.routeProjectId$,
    ]).pipe(
      take(1),
      map(([userBusinessSegmentId, userEntityId, projectId]) => {
        const rawFormData = this.projectFormGroup.getRawValue();
        const projectData = this.filterProjectsDataToStore({
          ...emptyProject(userEntityId, userBusinessSegmentId),
          ...rawFormData,
          enggPhaseDuration: rawFormData.enggPhaseDuration
            ? parseInt(rawFormData.enggPhaseDuration, 10)
            : null,
          executingEntityId: rawFormData.executingEntityId
            ? parseInt(rawFormData.executingEntityId, 10)
            : null,
          maxDesignPressure: rawFormData.maxDesignPressure
            ? parseInt(rawFormData.maxDesignPressure, 10)
            : null,
          maxDesignTemp: rawFormData.maxDesignTemp
            ? parseInt(rawFormData.maxDesignTemp, 10)
            : null,
          opPhaseDuration: rawFormData.opPhaseDuration
            ? parseInt(rawFormData.opPhaseDuration, 10)
            : null,
          waterDepth: rawFormData.waterDepth
            ? parseInt(rawFormData.waterDepth, 10)
            : null,
        });

        if (projectId) {
          projectData.projectId = projectId;
        }

        return projectData;
      })
    );
  }

  private filterProjectsDataToStore(data: any) {
    const projectDataKeyToSend = [
      ...Object.keys(this.projectFormGroup.value),
      'clientId',
      'projectStatus',
      'projectTypeId',
      'totalDuration',
    ];

    return Object.entries(data).reduce(
      (r, [k, v]) => (projectDataKeyToSend.includes(k) ? { ...r, [k]: v } : r),
      {}
    ) as ProjectFormData;
  }

  public isSavingProjectDisabled(): boolean {
    return this.submitted;
  }
}
